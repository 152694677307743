import React from "react";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import Footer from "../templates/footer";
import Navbar from "../templates/nav";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix, navigate } from "gatsby";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import theme from "../theme/themeFile";

const cookies = new Cookies();

export const Global = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;700;800&family=Noto+Serif+JP&family=Open+Sans:wght@300;400;600;700&family=Source+Sans+Pro:wght@400;600&display=swap');
body, html{
  margin: 0 !important;
  padding:0 !important;
  overflow-x: hidden;
}

* {
    font-family: 'Noto Serif JP', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', serif;
  }


h1, h2, h3, h4, h5, h6 {
  font-family: 'EB Garamond', serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #757575;
}

img {
  max-width: 70vw;
  max-height: 70vh;
  display: flex;
  margin: 0 auto;
}

h6 {
  text-align: center;
}
h1, h6 {
  font-size: 30px;
  line-height: 36px;
}
h2 {
  font-size: 24px;
  line-height: 28px;
}

h3{
  font-size: 20px;
  line-height: 28px;
}

p {
  font-family: 'Noto Serif JP', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', serif;
  font-size: 18px;
  line-height: 26px;
}

li {
  font-family: 'Noto Serif JP', serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
}

li::marker{
  font-size: 22px;
  font-family: 'Noto Serif JP', serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.heightGuard {
  min-height:calc(100vh - 250px);
}

.mobile {
  display: none !important;
}
.desktop {
  display: flex !important;
}
@media (max-width: 800px) {
  .mobile {
    display: flex !important;
  }
  .desktop {
    display: none !important;
  }
}
`;

const TemplateWrapper = ({ children }) => {
  const acceptedAgeGate = !!cookies.get("kp-age-gate-accepted");
  if (!acceptedAgeGate) {
    typeof window !== "undefined" && navigate("/agegate");
  }
  const { title, description } = useSiteMetadata();
  return (
    <ThemeProvider theme={theme}>
      <Global />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.jpg`}
        />
      </Helmet>
      <Navbar className="desktop" isVisible={true} />
      <div className="heightGuard" id="allcontent">
        {children}
      </div>
      <Footer />
    </ThemeProvider>
  );
};

export default TemplateWrapper;
