import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { ThemeProvider } from "styled-components";
import Theme from "../theme/themeFile";
import NavBar from "../components/NavBar/Navbar";
import MobileNavbar from "../components/NavBar/NavbarMobile";

const NavTemplate = () => {
  return (
    <ThemeProvider theme={Theme}>
      <StaticQuery
        query={graphql`
          query NavTemplate {
            markdownRemark(frontmatter: { templateKey: { eq: "nav" } }) {
              frontmatter {
                mobilelogo {
                  childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  publicURL
                }
                logo {
                  childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  publicURL
                }
                routing {
                  to
                  title
                }
              }
            }
          }
        `}
        render={(data) => (
          <>
            <NavBar {...data.markdownRemark.frontmatter} />
            <MobileNavbar {...data.markdownRemark.frontmatter} />
          </>
        )}
      />
    </ThemeProvider>
  );
};

export default NavTemplate;
