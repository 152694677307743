import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StyledMobileGuard } from "./NavbarMobile.styled";
import Hamburger from "../Icons/Hamburger";
import PreviewCompatibleImage from '../PreviewCompatibleImage';

const Navbar = ({ isVisible = true, routing, mobilelogo = false, ...rest }) => {
  const [active, setActive] = React.useState(false);
  const handleActive = () => setActive(!active);
  return (
    <>
      <StyledMobileGuard className="mobile" {...rest}>
        <Link to="/">
          <div
            className="logoContainer"
            loading="lazy"
          >
            <PreviewCompatibleImage imageInfo={mobilelogo} Tag="section" style={{backgroundSize: 'contain'} || "" }/>
          </div>
        </Link>
        <Hamburger
        className="hamburger"
          fill={"white"}
          style={{
            marginRight: `10px`,
          }}
          onClick={handleActive}
        />
        <div className={`routeContainer ${active ? "active" : ""}`}>
          {routing.map(({ to, title }) => (
            <Link onClick={handleActive} to={to}>
              {title}
            </Link>
          ))}
        </div>
      </StyledMobileGuard>
    </>
  );
};

Navbar.propTypes = {
  isVisible: PropTypes.bool,
  routing: PropTypes.shape({
    to: PropTypes.string,
    title: PropTypes.string,
  }),
  mobilelogo: PropTypes.any,
};

export default Navbar;
