import styled from "styled-components";

export const StyledMobileGuard = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.common.palette.darkGray};
  z-index: 99;
  .logoContainer {
    section {
      height: auto;
      min-height: 45px;
      height: 45px;
      background-position: center left;
      background-size: contain !important;
      margin-right: 80px;
      justify-content: space-around;
      width: 50vw;
      max-width: 300px;
      min-width: 100px;
    }
  }
  svg {
    cursor: pointer;
  }
  .hamburger { 
    z-index: 101;
  }
  .routeContainer {
    position: absolute;
    top: -100%;
    left: 0px;
    width: 100vw;
    transition: top 0.5s;
    z-index: 98;
    a {
      display: block;
      padding: 12px;
      font-size: 24px;
      color: white;
      text-decoration: none;
    }
    &.active {
      top: 52px;
    }
    background-color: ${({ theme }) => theme.common.palette.darkGray};
  }
`;
