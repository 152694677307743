import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={28} height={12} viewBox="0 0 28 12" {...props}>
      <title>{"Icon/Hamburger"}</title>
      <path
        d="M28 8v4H0V8h28zm0-8v4H0V0h28z"
        fill="#757575"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgComponent
