import React from "react";
import { Link } from "gatsby";
import {
  StyledFooter,
  FooterMainContainer,
  FooterSloganContainer,
  FooterIconContainer,
  Icon,
} from "./Footer.styled";

import PreviewCompatibleImage from "../PreviewCompatibleImage";

const ReplaceNewlinesWithbreaks = ({ data }) => {
  //console.log(data);
  const splitData = data.split("_n_");
  return (
    <>
      {splitData.map((split) => {
        return (
          <>
            {split}
            <br />
          </>
        );
      })}
    </>
  );
};
const Footer = ({
  slogan,
  marketingintegration,
  marketingintegrationlink,
  copyandtaglines,
  footerlinks,
  socialicons,
}) => {
  return (
    <StyledFooter>
      <FooterMainContainer>
        <span id="copyrightAndTaglines">
          <ReplaceNewlinesWithbreaks data={copyandtaglines} />
        </span>
        <br />
        <span id="links">
          {footerlinks.map(({ to, title }) => (
            <Link to={to}>{title}</Link>
          ))}
        </span>
        <br/>
        <a
          href={marketingintegrationlink}
          rel="noopener noreferrer"
          className="marketing"
          id="marketing"
        >
          {marketingintegration}
        </a>
      </FooterMainContainer>
      <FooterSloganContainer>
        <FooterIconContainer>
          {socialicons.map(({ icon, link }) => {
            return (
              <Link to={link}>
                <Icon>
                  <PreviewCompatibleImage imageInfo={icon || "" } Tag="section" />
                </Icon>
              </Link>
            );
          })}
        </FooterIconContainer>
        <h1>{slogan}</h1>
      </FooterSloganContainer>
    </StyledFooter>
  );
};

export default Footer;
