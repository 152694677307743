import styled from "styled-components";

export const StyledFooter = styled.footer`
  width: 100vw;
  height: auto;
  min-height: 115px;
  background: ${({ theme }) => theme.common.palette.darkGray};
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    flex-direction: column-reverse;
    padding-top: 20px;
  }
`;

export const FooterMainContainer = styled.div`
  ${({ theme }) => ({
    ...theme.common.typography.openSansNormal,
    color: theme.common.palette.white,
  })}
  width: 40%;
  margin: 20px 0px 0px 5px;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.common.palette.white};
    &:after {
      content: " | ";
    }
    &:last-of-type {
      &:after {
        content: "";
      }
    }
  }
  .marketing {
    color: rgba(255, 255, 255, 0.4);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    width: 80%;
  }
`;

export const FooterSloganContainer = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  h1 {
    ${({ theme }) => ({
      ...theme.common.typography.h3,
      color: theme.common.palette.white,
    })};
    font-weight: 300;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    width: auto;
  }
`;

export const FooterIconContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Icon = styled.div`
  width: 25px;
  height: 25px;
  background-size: fit;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 10px;
`;
