import styled from "styled-components";

export const StyledDesktopGuard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logoContainer, section {
    height: auto;
    min-height: 120px;
    background-position: center left;
    background-size: contain;
    margin-left: 40px;
    justify-content: space-around;
    width: 50vw;
    max-width: 500px;
    min-width: 100px;
  }
  svg {
    height: 82px;
    margin: 11px 8px;
  }
`;
export const RouteContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 40px;
  a {
    margin: 0px 8px;
    text-decoration: none;
    ${({ theme }) => theme.common.typography.nav}
  }
`;
