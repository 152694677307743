import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { ThemeProvider } from "styled-components";
import Theme from "../theme/themeFile";
import Footer from "../components/Footer/Footer";

const FooterTemplate = () => {
  return (
    <ThemeProvider theme={Theme}>
      <StaticQuery
        query={graphql`
          query FooterTemplate {
            markdownRemark(frontmatter: { templateKey: { eq: "footer" } }) {
              frontmatter {
                slogan
                marketingintegration
                marketingintegrationlink
                copyandtaglines
                footerlinks {
                  to
                  title
                }
                socialicons {
                  icon {
                    childImageSharp {
                      fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                    publicURL
                  }
                  link
                }
              }
            }
          }
        `}
        render={(data) => {
          return <Footer {...data.markdownRemark.frontmatter} />;
        }}
      />
    </ThemeProvider>
  );
};

export default FooterTemplate;
