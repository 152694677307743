import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StyledDesktopGuard, RouteContainer } from "./Navbar.styled";
import PreviewCompatibleImage from "../PreviewCompatibleImage";
const Navbar = ({ isVisible = true, routing, logo = false, ...rest }) => {
  return (
    <StyledDesktopGuard className="desktop" {...rest}>
      <Link to="/">
        <div
          className="logoContainer"
        >
          <PreviewCompatibleImage
            className="logoContainer"
            imageInfo={logo || "" }
            Tag="section"
            remainingStyle={{
              maxWidth: `350px`
            }}
          />
        </div>
      </Link>
      <RouteContainer>
        {routing.map(({ to, title }) => (
          <Link to={to}>{title}</Link>
        ))}
      </RouteContainer>
    </StyledDesktopGuard>
  );
};

Navbar.propTypes = {
  isVisible: PropTypes.bool,
  routing: PropTypes.shape({
    to: PropTypes.string,
    title: PropTypes.string,
  }),
  logo: PropTypes.any,
};

export default Navbar;
